import React from "react"

import Layout from "../components/layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import { checkIsAllsportsData } from "../domain_utils"


export default function rulePage({ data }) {
  const post = data.markdownRemark
  const date = post.frontmatter.date
  const locale = post.frontmatter.country
  
  const brandType = checkIsAllsportsData(post.frontmatter.country)
  const brand = brandType.brand
  const country = post.frontmatter.country === "cy_en" ? "cy" : post.frontmatter.country
  const hotLine = data.site.siteMetadata.countries[country]['hotLine']

  return (
    <Layout
      country={locale}
      hotLine={hotLine}
      countrySwitchOff={true}
    >
      <Head
        lang={country}
        title={`Rules ${brand}` + date}
        titleRu={"Правила Allsports " + date}
        slug={post.fields.slug}
      />
      <section>
        <h1>{post.frontmatter.title}</h1>
        <small>{date}</small>
        <div className="rules-doc" dangerouslySetInnerHTML={{ __html: post.html }} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ruleQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        country
      }
    }
    site {
      siteMetadata {
         countries {
            by {
              hotLine {
                tel
                title
              }
            }
            am {
              hotLine {
                tel
                title
              }
            }
            ru {
              hotLine {
                tel
                title
              }
            }
           lt {
             hotLine {
               tel
               title
             }
           }
           cy {
             hotLine {
               tel
               title
             }
           }
         }
      }
    },
  }
`
